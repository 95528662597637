<template>
  <div class="page-accounting">
    <accounting-navigation />
    <div class="agGrid-page container">
      <base-header>
        <show-archived-addon />
        <request-year-addon />
      </base-header>
      <div v-html="customHtml"></div>
      <base-ag-grid
        :columnDefs="columnDefs"
        :datasource="datasource"
        :cellValueChanged="cellValueChanged"
        :customRowId="(params) => { return params.data._id + '_' + params.data.client_id }"
        :pinnedTopRowDataCondition="pinnedTopRowDataCondition"
      />
    </div>
  </div>
</template>

<script>
// Components
import BaseAgGrid from '@/agGridV2/components/base.component.vue'
import AccountingNavigation from '@/components/AccountingNavigation.vue'
// API Init
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'
// Custom Renderers
import MonthInvoicesRenderer from '@/agGridV2/renderers/month-invoices.renderer.vue'
import ShowArchivedAddon from '@/agGridV2/components/header/show-archived.component.vue'
import RequestYearAddon from '@/agGridV2/components/header/request-year.component.vue'
import BaseHeader from '@/agGridV2/components/header/header.component.vue'

export default {
  components: {
    BaseHeader, ShowArchivedAddon, RequestYearAddon,
    BaseAgGrid,
    AccountingNavigation,
    // eslint-disable-next-line vue/no-unused-components
    MonthInvoicesRenderer
  },
  data() {
    return {
      datasource: null,
      customHtml: null,
      cellValueChanged: null,
      pinnedTopRowDataCondition: null,
      request: null,
      requestYear: null
    }
  },
  computed: {
    columnDefs() {
      const requestYear = this.$store.getters.getRequestYear(this.$route.name)
      const newColumnDefs = []
      let years = []
      if (requestYear === 'All') {
        years = ['2021', '2022', '2023', '2024']
      } else {
        years = [requestYear]
      }
      for (const year of years) {
        for (let month = 0; month <= 11; month++) {
          newColumnDefs.push({
            field: month + '_' + year,
            headerName: SimpleHelper.monthsLabels[month] + ' ' + year,
            cellRenderer: 'MonthInvoicesRenderer',
            filter: false,
            cellClassRules: {
              'active-project-month': (params) => {
                const month = params.colDef.field
                const active_months = params.data.active_months
                if (month && active_months) {
                  const index = active_months.findIndex((a) => a === month)
                  if (index !== -1) {
                    return true
                  }
                }
                return false
              }
            }
          })
        }
      }
      return [
        {
          field: 'name',
          headerName: 'Project',
          pinned: 'left',
          cellRenderer: function (params) {
            if (params.node.rowPinned) {
              return params.value
            } else {
              if (!params.value) return ''
              const status = params.data.status
              let intClass = ' '
              if (params.data.type.includes('-INT')) {
                intClass = ' project_int '
              }
              let html = `<div class="project_status${intClass}project_status--${status}">${params.value}</div>`
              if (params.data.gocardless) {
                html += '<div class="gocardless"></div>'
              }
              return html
            }
          }
        },
        {
          field: 'type',
          headerName: 'Type'
        },
        {
          field: 'client_name',
          headerName: SimpleHelper.columnLabels.CLIENT_NAME
        },
        {
          headerName: 'Reconciliation',
          valueGetter: (params) => {
            if (params.node.rowPinned) {
              return ''
            }
            const reconciliation = params.data.budget - params.data.paid_total
            return SimpleHelper.priceFormatter(reconciliation, true)
          },
          cellClassRules: {
            'not-editable': (params) => {
              if (params.node.rowPinned) {
                return false
              }
              return params.data.type !== 'DEV'
            }
          },
          filter: false
        },
        {
          field: 'budget',
          headerName: SimpleHelper.columnLabels.BUDGET_FROM_2021,
          editable: true,
          valueFormatter: (params) => {
            if (params.node.rowPinned) {
              return ''
            }
            return SimpleHelper.priceFormatter(params.value, true)
          },
          cellClassRules: {
            'not-editable': (params) => {
              if (params.node.rowPinned) {
                return false
              }
              return params.data.type !== 'DEV'
            }
          },
          filter: false
        },
        {
          field: 'full_total',
          headerName: SimpleHelper.columnLabels.TOTAL_FROM_2021,
          cellRenderer: (params) => {
            if (params.node.rowPinned) {
              let html = '<div>'
              html += SimpleHelper.priceFormatter(params.data.totalRevenue, true)
              if (params.data.totalUnpaidRevenue) {
                html += `<span style="color: #FF3D34"> (${SimpleHelper.priceFormatter(params.data.totalUnpaidRevenue)})</span>`
              }
              html += '</div>'
              return html
            }
            let html = '<div>'
            html += SimpleHelper.priceFormatter(params.data.paid_total + params.data.unpaid_total, true)
            if (params.data.unpaid_total) {
              html += `<span style="color: #FF3D34"> (${SimpleHelper.priceFormatter(params.data.unpaid_total)})</span>`
            }
            html += '</div>'
            return html
          },
          filter: 'agNumberColumnFilter'
        },
        ...newColumnDefs,
        {
          field: 'am',
          headerName: 'AM',
          valueFormatter: (params) => {
            if (params.node.rowPinned) {
              return ''
            }
            return params.value.name || ''
          }
        },
        {
          field: 'pm',
          headerName: 'PM',
          valueFormatter: (params) => {
            if (params.node.rowPinned) {
              return ''
            }
            return params.value.name || ''
          }
        },
        {
          field: 'ae',
          headerName: 'AE',
          valueFormatter: (params) => {
            if (params.node.rowPinned) {
              return ''
            }
            return params.value.name || ''
          }
        }
      ]
    }
  },
  async mounted() {
    this.datasource = {
      getRows: (params) => {
        params.request['search'] = this.$store.getters.getSearchQuery(this.$route.name)
        params.request['showArchived'] = this.$store.getters.getShowArchived(this.$route.name)
        MtApi.agGridAccountingDashboard(params.request).then((response) => {
          this.request = params.request
          const result = {
            ...{ name: `Total (${response.count || 0})`, ...response.totalRevenue }
          }
          for (let total of response.total) {
            result[total.month] = {
              total: total.totalPaid,
              unpaid: total.totalUnpaid
            }
          }
          this.pinnedTopRowDataCondition = () => {
            return {
              trigger: response.count,
              result: [result]
            }
          }
          params.success({ rowData: response.rows, rowCount: response.count })
        })
      }
    }
    this.cellValueChanged = (params) => {
      const context = params.context
      if (context && context?.event === 'update_invoice') {
        let serverData = {
          event: 'update_invoice',
          field: {
            invoice_id: context.invoice._id,
            name: 'applied_date',
            value: context.invoice.applied_date || null
          }
        }
        if (context.invoice.parent_id) {
          serverData = {
            event: 'update_sub_invoice',
            field: {
              invoice_id: context.invoice.parent_id,
              sub_invoice_id: context.invoice._id,
              name: 'applied_date',
              value: context.invoice.applied_date || null
            }
          }
        }
        MtApi.updateClient(params.data.client_id, serverData)
        const updateTotal = setTimeout(() => {
          this.rebuildTotal(params)
          clearTimeout(updateTotal)
        }, 600)
      } else {
        const data = {
          event: 'update_project',
          field: {
            name: params.colDef.field,
            value: params.value
          }
        }
        MtApi.updateProject(params.data._id, data)
      }
    }
  },
  methods: {
    rebuildTotal(params) {
      MtApi.agGridAccountingDashboard(this.request).then((response) => {
        const pinnedRow = params.api.getPinnedTopRow(0)
        const result = {
          ...{ name: `Total (${response.count || 0})`, ...response.totalRevenue }
        }
        for (let total of response.total) {
          result[total.month] = {
            total: total.totalPaid,
            unpaid: total.totalUnpaid
          }
        }
        params.api.flashCells({ rowNodes: [pinnedRow] })
        pinnedRow.updateData(result)
      })
    }
  }
}
</script>
